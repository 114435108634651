import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import CommonValueSetModal from '../../../../shared/valueSetModal';
import FmsTowerModule from '../../fmsTower';
export default {
  name: 'FmsFloorDetails',
  components: {
    ModelSelect,
    CommonValueSetModal,
    FmsTowerModule
  },
  data() {
    return {
      unsubscribe: null,
      showSelectedFloorRecord: false,
      showFmsTowerList: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      updateMode: false,
      fmsTower: {
        value: null,
        text: null
      },
      floorType: {
        value: null,
        text: null
      },
      crmFloor: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      fmsFloorName: null,
      fmsFloorCode: null,
      fmsFloorId: null,
      fmsTowerId: null,
    };
  },
  mounted() {
    this.eventBus.$off('showRecordDetails');
    this.eventBus.$on('showRecordDetails', recordDetails => {
      this.fmsFloorCode = recordDetails.floor_code;
      this.fmsFloorId = recordDetails.fms_floor_id;
      this.fmsFloorName = recordDetails.floor_name;
      this.crmFloor = {
        value: recordDetails.crm_floor_id,
        text: recordDetails.crm_floor_name
      };
      this.fmsTower = {
        fmsTowerId: recordDetails.fms_tower_id,
        text: recordDetails.fms_tower_name
      };
      this.floorType = {
        value: recordDetails.floor_type_vset,
        text: recordDetails.floor_type
      };
    });
    this.eventBus.$off('addCase');
    this.eventBus.$on('addCase', editMode => {
      this.updateMode = editMode;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addAndEditFmsFloor();
        }
        if (actionName === 'update') {
          this.updateMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsFloorId });
        }
      }
    });
  },
  methods: {
    openFmsTowerList() {
      this.showFmsTowerList = true;
    },
    selectFmsTower(towerData) {
      this.fmsTower = {
        fmsTowerId: towerData.fms_tower_id,
        value: towerData.tower_id,
        text: towerData.fms_tower_name
      };
      this.showFmsTowerList = false;
    },
    hideFmsTowerList() {
      this.showFmsTowerList = false;
    },
    addAndEditFmsFloor() {
      const payload = {
        floor_name: this.fmsFloorName,
        floor_code: this.fmsFloorCode,
        fms_floor_id: this.fmsFloorId,
        fms_tower_id: this.fmsTower.fmsTowerId,
        crm_floor_id: this.crmFloor.value,
        floor_type_vset: this.floorType.value
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addAndEditFmsFloor', payload)
        .then(response => {
          this.$emit('updateList');
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        this.crmFloor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FLOOR_TYPE_VSET) {
        this.floorType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        this.parent_value_set_id = this.fmsTower.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsTower.value) {
        this.fmsTower = this.defaultValue;
        this.crmFloor = this.defaultValue;
      } else if (vsetCode === this.crmFloor.value) {
        this.crmFloor = this.defaultValue;
      } else if (vsetCode === this.floorType.value) {
        this.floorType = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
