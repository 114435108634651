import commonHelper from '@/app/utility/common.helper.utility';
import FmsFloorDetails from './floorDetails';
import { ModelSelect } from 'vue-search-select';
import FmsProject from '../fmsProject';
import FmsTowerModule from '../fmsTower';

export default {
  name: 'FmsFloor',
  components: {
    ModelSelect,
    FmsFloorDetails,
    FmsProject,
    FmsTowerModule
  },
  props: ['projecName', 'towerName', 'towerId', 'showFmsFloorList'],
  watch: {
    currentPage: function() {
      this.getFmsFlooerList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsFlooerList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showSelectedFloorRecord: false,
      showFmsProjectList: false,
      showFmsTowerList: false,
      loader: false,
      editMode: false,
      payload: {},
      updateMode: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      proj: {
        value: null,
        text: null
      },
      projList: [
        {
          value: null,
          text: null
        }
      ],
      fmsTower: {
        value: null,
        text: null
      },
      fmsTowerList: [
        {
          value: null,
          text: null
        }
      ],
      fmsFloorName: null,
      fmsFloorCode: null,
      fmsFloorData: [],
      fmsFloorFields: [
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'floor_code',
          label: 'Floor Code'
        },
        {
          key: 'floor_type',
          label: 'Floor Type'
        },
        {
          key: 'crm_floor_name',
          label: 'CRM Floor'
        }
      ],
      defaultValue: {
        value: null,
        text: null
      },
      routeName: this.$router.currentRoute.name
    };
  },
  mounted() {
    if (this.showFmsFloorList) {
      this.proj.text = this.projecName;
      this.fmsTower.text = this.towerName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedFloorRecord = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsFlooerList',
            'fms-floor',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    openProjectList() {
      this.showFmsProjectList = true;
    },
    selectFmsProject(projectData) {
      this.proj = {
        value: projectData.fms_prj_id,
        text: projectData.fms_prj_name
      };
      this.fmsTower = {
        value: null,
        text: null
      };
      this.showFmsProjectList = false;
    },
    hideFmsProjectList() {
      this.showFmsProjectList = false;
    },
    openFmsTowerList() {
      if (this.proj.value) {
        this.showFmsTowerList = true;
      } else {
        alert('Please Select Project First');
      }
    },
    selectFmsTower(towerData) {
      this.fmsTower = {
        value: towerData.fms_tower_id,
        text: towerData.fms_tower_name
      };

      this.showFmsTowerList = false;
    },
    hideFmsTowerList() {
      this.showFmsTowerList = false;
    },
    getFmsFlooerList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        fms_tower_id: this.towerId ? this.towerId : this.fmsTower.value,
        fms_floor_code: this.fmsFloorCode,
        fms_floor_name: this.fmsFloorName
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsFlooerList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsFloorData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsFloor') {
        this.showSelectedFloorRecord = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectFmsFloor', rowData);
      }
    },
    updateList() {
      this.getFmsFlooerList();
    },
    clearFilters() {
      this.proj = this.defaultValue;
      this.fmsTower = this.defaultValue;
      this.fmsFloorName = null;
      this.fmsFloorCode = null;
      this.totalRows = null;
      this.fmsFloorData = [];
    },
    hideFloorRecord() {
      this.showSelectedFloorRecord = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.proj.value) {
        this.proj = this.defaultValue;
        this.fmsTower = this.defaultValue;
      } else if (vsetCode === this.fmsTower.value) {
        this.fmsTower = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
