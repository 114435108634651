import commonHelper from '@/app/utility/common.helper.utility';
import FmsTowerDetails from './fmsTowerDetails';
import { ModelSelect } from 'vue-search-select';
import FmsProject from '../fmsProject';

export default {
  name: 'FmsTower',
  components: {
    ModelSelect,
    FmsTowerDetails,
    FmsProject
  },
  props: ['projecName', 'projectId', 'showFmsTowerList'],
  watch: {
    currentPage: function() {
      this.getFmsTowerList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsTowerList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showSelectedTowerRecord: false,
      showFmsProjectList: false,
      loader: false,
      editMode: false,
      updateMode: false,
      totalRows: null,
      payload: {},
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      project: {
        value: null,
        text: null
      },
      fmsTowerName: '',
      fmsTowerCode: '',
      fmsTowerData: [],
      fmsTowerFields: [
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'fms_tower_code',
          label: 'Tower Code'
        },
        {
          key: 'tower_name',
          label: 'CRM Tower'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      defaultValue: {
        value: null,
        text: null
      },
      routeName: this.$router.currentRoute.name
    };
  },
  mounted() {
    if (this.showFmsTowerList) {
      this.project.text = this.projecName;
      this.project.value = this.projectId;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedTowerRecord = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsTowerList',
            'fms-tower',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    openProjectList() {
      this.showFmsProjectList = true;
    },
    selectFmsProject(projectData) {
      this.project = {
        value: projectData.fms_prj_id,
        text: projectData.fms_prj_name
      };
      this.showFmsProjectList = false;
    },
    hideFmsProjectList() {
      this.showFmsProjectList = false;
    },
    updateList() {
      this.getFmsTowerList();
    },
    getFmsTowerList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        fms_project_id: this.projectId ? this.projectId : this.project.value,
        fms_tower_code: this.fmsTowerCode,
        fms_tower_name: this.fmsTowerName
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsTowerList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsTowerData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsTower') {
        this.showSelectedTowerRecord = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectFmsTower', rowData);
      }
    },
    clearFilters() {
      this.project = this.defaultValue;
      this.fmsTowerName = '';
      this.fmsTowerCode = '';
      this.totalRows = null;
      this.fmsTowerData = [];
    },
    hideProjectRecord() {
      this.showSelectedTowerRecord = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
