import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import FmsProject from '../../fmsProject';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'FmsTowerDetails',
  components: {
    ModelSelect,
    DatePicker,
    FmsProject
  },
  data() {
    return {
      showSelectedProjectRecord: false,
      showFmsProjectList: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      updateMode: false,
      crmTower: {
        value: null,
        text: null
      },
      crmTowerList: [
        {
          value: null,
          text: null
        }
      ],
      fmsProject: {
        value: null,
        text: null
      },
      fmsTowerName: null,
      fmsTowerCode: null,
      fmsTowerId: 0,
      start_date_end_date: null,
      start_date: null,
      end_date: null,
      defaultValue: {
        value: null,
        text: null
      },
      fmsProjectId: null
    };
  },
  mounted() {
    this.eventBus.$off('showRecordDetails');
    this.eventBus.$on('showRecordDetails', recordDetails => {
      this.fmsTowerCode = recordDetails.fms_tower_code;
      this.fmsTowerId = recordDetails.fms_tower_id;
      this.fmsTowerName = recordDetails.fms_tower_name;
      this.crmTower = {
        value: recordDetails.tower_id,
        text: recordDetails.tower_name
      };
      this.fmsProject = {
        fmsProjectId: recordDetails.fms_project_id,
        text: recordDetails.fms_project_name
      };
      this.start_date = recordDetails.start_date;
      this.end_date = recordDetails.end_date;
    });
    this.eventBus.$off('addCase');
    this.eventBus.$on('addCase', editMode => {
      this.updateMode = editMode;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addAndEditTower();
        }
        if (actionName === 'update') {
          this.updateMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsTowerId });
        }
      }
    });
  },
  methods: {
    openProjectList() {
      this.showFmsProjectList = true;
    },
    selectFmsProject(projectData) {
      this.fmsProject = {
        fmsProjectId: projectData.fms_prj_id,
        value: projectData.crm_prj_id,
        text: projectData.fms_prj_name
      };
      this.showFmsProjectList = false;
    },
    hideFmsProjectList() {
      this.showFmsProjectList = false;
    },
    addAndEditTower() {
      const payload = {
        fms_tower_id: this.fmsTowerId,
        crm_tower_id: this.crmTower.value,
        start_date: this.start_date,
        end_date: this.end_date,
        fms_project_id: this.fmsProject.fmsProjectId,
        fms_tower_code: this.fmsTowerCode,
        fms_tower_name: this.fmsTowerName
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addAndEditTower', payload)
        .then(response => {
          this.$emit('updateList');
          this.updateMode = false;
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        this.crmTower = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        this.parent_value_set_id = this.fmsProject.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsProject.value) {
        this.fmsProject = this.defaultValue;
        this.crmTower = this.defaultValue;
      } else if (vsetCode === this.crmTower.value) {
        this.crmTower = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
